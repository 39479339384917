import React, { useState } from "react";
import styles from './PYMES.module.css'

const PYMES = () => {
    return (
        <div className={styles.headerMainContainer}>

            <div className={styles.headerContent}>

                <img className={styles.image} src={require('../../assets/pymes.svg').default} />

                <div className={styles.textContent}>
                    <h1 className={styles.title}>PYMES</h1>
                    <h2 className={styles.subTitle}>Brindamos servicio especializado para pequeñas y medianas empresas, sabemos que es difícil encontrar soluciones de software y de diseño para las mismas, debido a eso adaptamos nuestros planes y costos para que puedan cumplir sus objetivos.</h2>
                    <h2 className={styles.subTitle}>A nosotros si nos importa tu negocio.</h2>
                </div>

            </div>



        </div>
    );

}

export default PYMES;