import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { NavLink} from 'react-router-dom'
import { Link, animateScroll as scroll, } from 'react-scroll';


import './Menu.css'

const Menu = () => {

    const navigate = useNavigate();

    const [showNavbar, setShowNavbar] = useState(false)

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }

    return (
        <nav className="navbar">
            <div className="container">
                <div className="logo">
                    <img src={require('../../assets/iconMoncake.png')} />
                </div>
                <div className="menu-icon" onClick={handleShowNavbar}>
                    <i className="fa-solid fa-bars"></i>
                </div>
                <div className={`nav-elements  ${showNavbar && 'active'}`}>
                    <ul>
                        <li>
                            <a onClick={()=>{
                                 navigate('/', { replace: false });
                            }}>Inicio</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );

}

export default Menu;