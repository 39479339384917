import React, { useState } from "react";
import styles from './Experience.module.css'

const Experience = () => {
    return (
        <div className={styles.headerMainContainer}>

            <div className={styles.headerContent}>
                <h1 className={styles.hintTitle}>¿Quiénes somos?</h1>
                <h1 className={styles.title}>Nosotros</h1>
                <h2 className={styles.subTitle}>Somos una empresa altamente calificada con los mejores colaboradores, trabajamos con todo tipo de clientes sin importar su tamaño, nos enfocamos en el desarrollo a la medida contando con experiencia en el desarrollo de plataformas Web y móviles, no importa si eres una empresa grande o una pequeña, siempre te daremos un producto de calidad excepcional.</h2>
                <img className={styles.image} src={require('../../assets/iconMoncake.png')} />
            </div>

        </div>
    );

}

export default Experience;