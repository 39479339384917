import React, { useState } from "react";
import styles from './Footer.module.css'

const Footer = () => {
    return (
        <div className={styles.headerMainContainer}>

            <div className={styles.headerContent}>
                <a href="https://www.facebook.com/profile.php?id=61555735427171"><i className="fa-brands fa-facebook"></i></a>

                <img className={styles.image} src={require('../../assets/iconMoncake.png')} />
            </div>

        </div>
    );

}

export default Footer;