class UserService {

    sendEmail = async (text) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                body: JSON.stringify({ text: text })
            };

            const response = await fetch('https://findmecr.com/api/user/sendEmail', requestOptions);
            if (!response.ok) { }
            const data = await response.json();
            return data;

        } catch (error) {
            console.error("Error fetching data:", error);
            return "";
        }
    };
}

const userServiceInstance = new UserService();
export default userServiceInstance;