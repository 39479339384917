import styles from './cvMain.module.css';
import Menu from './Components/Menu/Menu';

import Header from './Components/Header/header';
import Me from './Components/Me/me';
import Projects from './Components/projects/projects';
import Tech from './Components/Tech/tech';
import Footer from './Components/Footer/Footer';

import { Element } from 'react-scroll';

function CvMain() {
  return (
    <div>

      <Menu />


      <div className={styles.main}>
        <Header />
        <Element name="initJo">
          <Me />
        </Element>
        <Element name="projects">
          <Projects />
        </Element>
        <Tech />
        <Footer />

      </div>


    </div>
  );
}

export default CvMain;
