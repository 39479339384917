import React, { useState } from "react";
import styles from './Footer.module.css'

const Footer = () => {
    return (
        <div className={styles.headerMainContainer}>

            <div className={styles.headerContent}>
                <i className="fa-brands fa-linkedin" onClick={()=> window.open("https://www.linkedin.com/in/josu%C3%A9-vargas-%C3%A1lvarez-81a46b1a3/", "_blank")}></i>

                <div className="logo">
                    <h1>{"<Josue/>"}</h1>
                </div>
            </div>

        </div>
    );

}

export default Footer;