import React, { useState } from "react";
import styles from './ContactUs.module.css'
import Loading from '../Loading/Loading';
import userServiceInstance from '../../api/userService';

const ContactUs = () => {

    const [loading, setLoading] = useState(false);
    const [sendedMessage, setSendedMessage] = useState(false);
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [text, setText] = useState("");


    return (

        <div className={styles.headerMainContainer}>

            <div className={styles.headerContent}>
                <h1 className={styles.title}>Contáctenos</h1>
                <h2 className={styles.subTitle}>Estamos disponibles para escuchar de tu proyecto o cualquier consulta que tengas.</h2>

                <div className={styles.phone}>
                    <i className="fa-solid fa-phone"></i>
                    <p>86672823</p>
                </div>

                <form
                    className={styles.form}
                    onSubmit={e => e.preventDefault()}
                >
                    <h2>Correo electrónico</h2>
                    <input type="email" id="email" name="email" required value={email} onChange={(inputValue) => {
                        setEmail(inputValue.target.value)
                    }} />

                    <h2>Asunto</h2>
                    <input type="text" id="subject" name="subject" required value={subject} onChange={(inputValue) => {
                        setSubject(inputValue.target.value)
                    }} />

                    <h2>Mensaje</h2>
                    <textarea rows="10" id="message" name="message" required value={text} onChange={(inputValue) => {
                        setText(inputValue.target.value)
                    }}></textarea>

                    {loading ? (<Loading />) :

                        sendedMessage ?
                            (<button className={styles.sendButton} onClick={async () => { }}>Mensaje enviado</button>) : (
                                <button className={styles.sendButton} onClick={async () => {
                                    if (email, subject, text) {
                                        setLoading(true);
                                        var result = await userServiceInstance.sendEmail(
                                            `Email: ${email}\nAsunto: ${subject}\nMensaje: ${text}`
                                        );
                                        setSendedMessage(true);
                                        setLoading(false);
                                    }
                                }}>Enviar<i className="fa-solid fa-paper-plane"></i></button>)
                    }
                </form>
            </div>
        </div>
    );

}

export default ContactUs;