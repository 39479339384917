import React, { useState } from "react";
import styles from './projects.module.css'
import { Link } from 'react-scroll';

const Projects = () => {
    return (
        <div className={styles.headerMainContainer}>

            <div className={styles.headerContent}>
                <h1 className={styles.hintTitle}>Mi trabajo</h1>
                <h1 className={styles.title}>Proyectos.</h1>
                <h2 className={styles.subTitle}>Algunos proyectos en los que he trabajado como desarrollador, estos se encuentran actualmente en producción, en todos estos proyectos mi papel fue como desarrollador principal.</h2>
            </div>

            <div className={`${styles.headerContentRow}`}>
                <div className={styles.cardProject}>
                    <img src={require('../../../../assets/tapp.png')} />
                    <div className={styles.cardTextContainer}>
                        <h1 className={styles.hintTitleBold}>Tapp</h1>
                        <h2 className={styles.subTitle}>Aplicación de loyalty multi comercio en la que colaboré como desarrollador Android.</h2>

                        <div className={styles.containerTechs}>
                            <i className="fa-brands fa-android"></i>
                        </div>

                        <button className={styles.button} onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.soin.loyalty", "_blank")}>Visitar </button>
                    </div>
                </div>

                <div className={styles.cardProject}>
                    <img src={require('../../../../assets/edus.png')} />
                    <div className={styles.cardTextContainer}>
                        <h1 className={styles.hintTitleBold}>Edus</h1>
                        <h2 className={styles.subTitle}>Aplicación de control médico en la que colaboré como desarrollador Android.</h2>

                        <div className={styles.containerTechs}>
                            <i className="fa-brands fa-android"></i>
                        </div>

                        <button className={styles.button}  onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.ccss.expedienteunico", "_blank")}>Visitar </button>
                    </div>
                </div>

                <div className={styles.cardProject}>
                    <img src={require('../../../../assets/colono.png')} />
                    <div className={styles.cardTextContainer}>
                        <h1 className={styles.hintTitleBold}>Colono App</h1>
                        <h2 className={styles.subTitle}>Aplicación de loyalty de Grupo Colono en la que colaboré como desarrollador Android.</h2>

                        <div className={styles.containerTechs}>
                            <i className="fa-brands fa-android"></i>
                        </div>

                        <button className={styles.button} onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.soin.colono", "_blank")}>Visitar </button>
                    </div>
                </div>

                <div className={styles.cardProject}>
                    <img className={styles.pcImage} src={require('../../../../assets/findmeCR.png')} />
                    <div className={styles.cardTextContainer}>
                        <h1 className={styles.hintTitleBold}>FindMeCr.com</h1>
                        <h2 className={styles.subTitle}>Aplicación web para el registro y adopción de mascotas, en la que colaboré como desarrollador único.</h2>

                        <div className={styles.containerTechs}>
                            <i className="fa-brands fa-react"></i>
                            <i className="fa-brands fa-node-js"></i>
                            <i className="fa-brands fa-sass"></i>
                        </div>

                        <button className={styles.button} onClick={()=> window.open("https://findmecr.com/LoginPage", "_blank")}>Visitar </button>
                    </div>
                </div>

                <div className={styles.cardProject}>
                    <img className={styles.pcImage} src={require('../../../../assets/moncakeWeb.png')} />
                    <div className={styles.cardTextContainer}>
                        <h1 className={styles.hintTitleBold}>Moncake</h1>
                        <h2 className={styles.subTitle}>Página web de empresa de desarrollo y diseños gráfico Moncake, en la que colaboré como desarrollador único.</h2>

                        <div className={styles.containerTechs}>
                            <i className="fa-brands fa-react"></i>
                            <i className="fa-brands fa-node-js"></i>
                        </div>

                        <button className={styles.button} onClick={()=> window.open("https://www.desarrollomoncake.com/", "_blank")}>Visitar </button>
                    </div>
                </div>


            </div>



        </div >
    );

}

export default Projects;