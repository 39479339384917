import Menu from './Components/Menu/Menu';
import Header from './Components/Header/Header';
import Experience from './Components/Experience/Experience';
import PYMES from './Components/PYMES/PYMES';
import Development from './Components/Development/development';
import Projects from './Components/Projects/Projects';
import ContactUs from './Components/ContactUs/ContactUs';
import Footer from './Components/Footer/Footer';
import styles from './App.module.css';

import { Element } from 'react-scroll';


function App() {
  return (
    <div>

      <Menu />


      <div className={styles.main}>

        <Element name="init">
          <Header />
        </Element>
        
        <PYMES />
        <Development />

        <Element name="us">
          <Experience />
        </Element>

        <Projects />

        <Element name="contact">
          <ContactUs />
        </Element>


        <Footer />

      </div>


    </div>
  );
}

export default App;
