import { useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState, useRef } from "react";


import styles from './TemplateDetail.module.css';

function TemplateDetail() {
    const navigate = useNavigate();
    const location = useLocation();
    const [template, setTemplate] = useState(false);


    useEffect(() => {
        if (location.state != null && location.state.item) {
            setTemplate(location.state.item);
        }
    });

    return (
        <div className={styles.frameContainer}>
            <button onClick={()=>{
                navigate('/templates', { replace: false });
            }} className={styles.goBack}>Volver a ver plantillas</button>
            <iframe className={styles.frame} src={`webPreviews/${template}/index.html`}></iframe>
        </div>

    );
}

export default TemplateDetail;