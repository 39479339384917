import React, { useState } from "react";
import styles from './me.module.css'
import { Link } from 'react-scroll';

const Me = () => {
    return (
        <div className={styles.headerMainContainer}>
            <div className={`${styles.headerContentRow}`}>
                <div className={styles.headerContent}>
                    <h1 className={styles.hintTitle}>Sobre mi</h1>
                    <h1 className={styles.title}>¿Quién Soy?.</h1>
                    <h2 className={styles.subTitle}>
                        Mi nombre es Josué Vargas Álvarez, soy un desarrollador apasionado por la programación con experiencia en múltiples tecnologías.<br /><br />
                        Me especializo en el desarrollo de plataformas web y móviles, contando con años de experiencia en la rama de la informática, además de esto he colaborado como desarrollador en proyectos importantes a nivel nacional.
                    </h2>
                </div>
            </div>



        </div>
    );

}

export default Me;