import React, { useState } from "react";
import styles from './header.module.css'
import doc from '../../../../assets/Josue Vargas Alvarez Developer.pdf'

const Header = () => {
    return (
        <div className={styles.headerMainContainer}>
            <div className={`${styles.headerContentRow}`}>
                <div className={styles.headerContent}>

                    <h1 className={styles.hintTitle}>Hola, soy</h1>
                    <h1 className={styles.title}>Josué Vargas Álvarez</h1>
                    <h2 className={styles.subTitle}>Full Stack Developer</h2>
                    <div className={styles.headerButtons}>
                        <button className={styles.button}
                            onClick={() => window.open(doc, "_blank")}
                        ><i className="fa-solid fa-file-arrow-down"></i> Descargar CV</button>
                        <i className="fa-brands fa-linkedin-in" onClick={() => window.open("https://www.linkedin.com/in/josu%C3%A9-vargas-%C3%A1lvarez-81a46b1a3/", "_blank")}></i>
                    </div>
                </div>

                <img src={require('../../../../assets/josue.jpg')} />
            </div>



        </div>
    );

}

export default Header;