import { useNavigate } from 'react-router-dom';

import Menu from '../../../Components/MenuNavigate/Menu';
import Footer from '../../../Components/Footer/Footer';

import styles from './TemplateDashboard.module.css';

function TemplateDashboard() {
    const navigate = useNavigate();

    return (
        <div>

            <Menu />


            <div className={styles.main}>

                <div className={styles.templatesContainer}>
                    <img onClick={() => {
                        navigate('/templateDetail', { state: { item: "limelight-html" }, replace: false });
                    }} className={styles.imgTemplate} src={require('../../../assets/limelight.jpeg')} />

                    <img onClick={() => {
                        navigate('/templateDetail', { state: { item: "zay-shop-html" }, replace: false });
                    }} className={styles.imgTemplate} src={require('../../../assets/zay-shop.jpeg')} />
                    <img onClick={() => {
                        navigate('/templateDetail', { state: { item: "webwing-html" }, replace: false });
                    }} className={styles.imgTemplate} src={require('../../../assets/webwing.jpeg')} />
                    <img onClick={() => {
                        navigate('/templateDetail', { state: { item: "heustonn-html" }, replace: false });
                    }} className={styles.imgTemplate} src={require('../../../assets/heustonn.jpeg')} />
                </div>


                <Footer />

            </div>


        </div>
    );
}

export default TemplateDashboard;