import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';
import CvMain from './views/cv/cvMain';


import TemplateDashboard from './views/templates/templatesDashboard/TemplateDashboard';
import TemplateDetail from './views/templates/templatesDetail/TemplateDetail';

import { createBrowserRouter, RouterProvider, } from "react-router-dom";



const baseRouteList = [
  {
    path: "*",
    element: <App />,
  },
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/josuevargas",
    element: <CvMain />,
  },
  {
    path: "/templates",
    element: <TemplateDashboard />,
  },
  {
    path: "/templateDetail",
    element: <TemplateDetail />,
  },
]

const router = createBrowserRouter(baseRouteList);

function AppRouter() {
  return <RouterProvider router={router} />
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>
);
