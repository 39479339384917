import React, { useState } from "react";
import styles from './development.module.css'

const Development = () => {
    return (
        <div className={styles.headerMainContainer}>

            <div className={styles.headerContent}>

                <div className={styles.textContent}>
                    <h1 className={styles.hintTitle}>Nuestra especialidad</h1>
                    <h1 className={styles.title}>Desarrollo Web y Móvil.</h1>
                    <h2 className={styles.subTitle}>Nuestra empresa se especializa en el desarrollo de páginas web y aplicaciones móviles, nos encargamos de todo tipo de proyectos satisfaciendo las necesidades del cliente, aportando nuestro dinamismo y experiencia para que tu proyecto llegue al siguiente nivel.</h2>
                </div>

                <img className={styles.image} src={require('../../assets/development.png')} />

            </div>



        </div>
    );

}

export default Development;