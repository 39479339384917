import React, { useState } from "react";
import styles from './Header.module.css'
import { Link } from 'react-scroll';

const Header = () => {
    return (
        <div className={styles.headerMainContainer}>

            <div className={styles.headerContent}>
                <h1 className={styles.title}>Nos especializados en<br />proyectos Web, aplicaciones móviles y diseño gráfico</h1>
                <h2 className={styles.subTitle}>Cotiza hoy tus proyectos 100% gratis.</h2>
                <Link activeClass="active" smooth spy to="contact"><button className={styles.button}>Cuéntanos tu proyecto</button></Link>


            </div>

        </div>
    );

}

export default Header;