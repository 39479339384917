import React, { useState } from "react";
import styles from './Projects.module.css'

const Projects = () => {
    return (
        <div className={styles.headerMainContainer}>

            <div className={styles.headerContent}>

                <img className={styles.image} src={require('../../assets/dogScan.png')} />

                <div className={styles.textContent}>
                <h1 className={styles.hintTitle}>Nos importa nuestro mundo</h1>
                    <h1 className={styles.title}>Proyectos sin fines de lucro</h1>
                    <h2 className={styles.subTitle}>Nos importan múltiples causas, una de ellas es los animales perdidos y en busca de hogar, debido a esto lanzamos una plataforma completamente gratuita para que las personas puedan asegurar a sus mascotas y ayudar a animales a encontrar un nuevo hogar.</h2>
                    
                    <a href="https://findmecr.com/LoginPage"><button className={styles.button}>Visitar</button></a>
                
                </div>

            </div>



        </div>
    );

}

export default Projects;