import React, { useState } from "react";
import styles from './tech.module.css'
import { Link } from 'react-scroll';
import { RiFlutterFill } from "react-icons/ri";

import Slider from 'react-infinite-logo-slider'

const Tech = () => {
    return (
        <div className={styles.headerMainContainer}>
            <div className={`${styles.headerContentRow}`}>
                <div className={styles.headerContent}>
                    <h1 className={styles.hintTitle}>Tecnologías</h1>
                    <h1 className={styles.title}>Habilidades.</h1>
                </div>

                <Slider
                    width="300px"
                    duration={40}
                    toRight={true}
                    pauseOnHover={false}
                    blurBorders={false}
                    blurBoderColor={'#fff'}
                >
                    <Slider.Slide>
                        <div className={styles.itemScroll}>
                            <i className="fa-brands fa-android"></i>
                            <p>Android</p>
                        </div>
                    </Slider.Slide>

                    <Slider.Slide>
                        <div className={styles.itemScroll}>
                            <RiFlutterFill size="40px" />
                            <p>Flutter</p>
                        </div>
                    </Slider.Slide>

                    <Slider.Slide>
                        <div className={styles.itemScroll}>
                            <i className="fa-brands fa-react"></i>
                            <p>React</p>
                        </div>
                    </Slider.Slide>

                    <Slider.Slide>
                        <div className={styles.itemScroll}>
                            <i className="fa-brands fa-node-js"></i>
                            <p>NodeJs</p>
                        </div>
                    </Slider.Slide>

                    <Slider.Slide>
                        <div className={styles.itemScroll}>
                            <i className="fa-solid fa-database"></i>
                            <p>SQL</p>
                        </div>
                    </Slider.Slide>

                    <Slider.Slide>
                        <div className={styles.itemScroll}>
                            <i className="fa-brands fa-sass"></i>
                            <p>SASS</p>
                        </div>
                    </Slider.Slide>
                </Slider>

                <Slider
                    width="350px"
                    duration={40}
                    toRight={false}
                    pauseOnHover={false}
                    blurBorders={false}
                    blurBoderColor={'#fff'}
                >

                    <Slider.Slide>
                        <div className={styles.itemScroll}>
                            <i className="fa-solid fa-database"></i>
                            <p>SQL</p>
                        </div>
                    </Slider.Slide>

                    <Slider.Slide>
                        <div className={styles.itemScroll}>
                            <i className="fa-brands fa-node-js"></i>
                            <p>NodeJs</p>
                        </div>
                    </Slider.Slide>

                    <Slider.Slide>
                        <div className={styles.itemScroll}>
                            <i className="fa-brands fa-sass"></i>
                            <p>SASS</p>
                        </div>
                    </Slider.Slide>

                    <Slider.Slide>
                        <div className={styles.itemScroll}>
                            <i className="fa-brands fa-android"></i>
                            <p>Android</p>
                        </div>
                    </Slider.Slide>

                    <Slider.Slide>
                        <div className={styles.itemScroll}>
                            <RiFlutterFill size="40px" />
                            <p>Flutter</p>
                        </div>
                    </Slider.Slide>

                    <Slider.Slide>
                        <div className={styles.itemScroll}>
                            <i className="fa-brands fa-react"></i>
                            <p>React</p>
                        </div>
                    </Slider.Slide>
                </Slider>
            </div>



        </div>
    );

}

export default Tech;